import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Grid, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import ConfigurationsBlackIcon from '../components/icons/configurations-black'
import DownloadIcon from '../components/icons/download'
import Layout from '../components/layout'
import UniformReportModal from '../components/modal/uniform_report_modal'
import { useAppDispatch } from '../hooks/store'
import { api } from '../services/api'
import {
  useGetFinancialReportQuery,
  useGetIncidentReportQuery,
  useGetInjuryReportQuery,
  useGetRegistrationReportQuery,
  useLazyGetUniformReportQuery,
} from '../services/api/endpoints/reportings'
import { ActiveRole } from '../utils/types'

const Container = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up(900)]: {
        width: '90%',
      },
    }
  }}
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 0px 0px 20px 0px;
  padding: 1rem;
  height: 40px;
  border-radius: 0.4rem;
`
const ReportingPage = () => {
  const dispatch = useAppDispatch()
  const { user } = useAuth0()
  const [activeRole, setActiveRole] = useState<ActiveRole | null>(null)

  useEffect(() => {
    if (user) {
      const userMetaData = user['https://tritagrugby.com/user_metadata']
      const { activeRole: userActiveRole } = userMetaData
      setActiveRole(userActiveRole)
    } else {
      setActiveRole(null)
    }
  }, [user])

  const [showUniformReportModal, setShowUniformReportModal] =
    useState<boolean>(false)
  const { data: financialData, isLoading: financialLoading } =
    useGetFinancialReportQuery()
  const { data: incidentData, isLoading: incidentLoading } =
    useGetIncidentReportQuery()
  const { data: injuryData, isLoading: injuryLoading } =
    useGetInjuryReportQuery()
  const { data: registrationData, isLoading: registrationLoading } =
    useGetRegistrationReportQuery()

  const [getUniformReport, { data: uniformData, isLoading: uniformLoading }] =
    useLazyGetUniformReportQuery()

  const resetUniformData = () => {
    dispatch(api.util.resetApiState())
  }

  return (
    <Layout label1="Reporting" url="/reporting">
      {financialData && financialData.data && !financialLoading && (
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <Container>
              Financial Report
              <CSVLink
                filename="Financial Report"
                style={{ textDecoration: 'none', color: 'black' }}
                data={financialData.data}
              >
                <DownloadIcon />
              </CSVLink>
            </Container>
          </Grid>
        </Grid>
      )}
      {incidentData && incidentData.data && !incidentLoading && (
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <Container>
              Incident Report
              <CSVLink
                filename="Incident Report"
                style={{ textDecoration: 'none', color: 'black' }}
                data={incidentData.data}
              >
                <DownloadIcon />
              </CSVLink>
            </Container>
          </Grid>
        </Grid>
      )}
      {injuryData && injuryData.data && !injuryLoading && (
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <Container>
              Injury Report
              <CSVLink
                filename="Injury Report"
                style={{ textDecoration: 'none', color: 'black' }}
                data={injuryData.data}
              >
                <DownloadIcon />
              </CSVLink>
            </Container>
          </Grid>
        </Grid>
      )}
      {registrationData && registrationData.data && !registrationLoading && (
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <Container>
              Registration Report
              <CSVLink
                filename="Registration Report"
                style={{ textDecoration: 'none', color: 'black' }}
                data={registrationData.data}
              >
                <DownloadIcon />
              </CSVLink>
            </Container>
          </Grid>
        </Grid>
      )}
      {activeRole && activeRole.type === 1 && (
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <Container>
              Uniform Report
              <ConfigurationsBlackIcon
                onClick={() => setShowUniformReportModal(true)}
              />
              {showUniformReportModal && (
                <UniformReportModal
                  setModal={setShowUniformReportModal}
                  reportLoading={uniformLoading}
                  reportData={uniformData}
                  reportReset={resetUniformData}
                  generate={getUniformReport}
                />
              )}
            </Container>
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(ReportingPage)
