import { api } from '..'

export interface GetUniformReportParams {
  association?: string
  startDate?: string
  endDate?: string
  season?: string
}

export const reportingsApi = api.injectEndpoints({
  endpoints: build => ({
    getFinancialReport: build.query<any, void>({
      query: () => `reportings/financial`,
    }),
    getIncidentReport: build.query<any, void>({
      query: () => `reportings/incident`,
    }),
    getInjuryReport: build.query<any, void>({
      query: () => `reportings/injury`,
    }),
    getRegistrationReport: build.query<any, void>({
      query: () => `reportings/registration`,
    }),
    getUniformReport: build.query<any, GetUniformReportParams>({
      query: params => {
        const { association, season, startDate, endDate } = params
        return {
          url: `reportings/uniform`,
          params: {
            association: association ?? undefined,
            season: season ?? undefined,
            startDate: startDate ?? undefined,
            endDate: endDate ?? undefined,
          },
        }
      },
      providesTags: ['reporting-uniform'],
    }),
  }),
})

export const {
  useGetFinancialReportQuery,
  useGetIncidentReportQuery,
  useGetInjuryReportQuery,
  useGetRegistrationReportQuery,
  useLazyGetUniformReportQuery,
} = reportingsApi
