import {
  Button,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  Select,
  styled,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'
import { CSVLink } from 'react-csv'
import { GetUniformReportParams } from 'manage-tritag/services/api/endpoints/reportings'
import moment from 'moment'
import {
  Season,
  useGetSeasonsQuery,
} from 'manage-tritag/services/api/endpoints/seasons'
import {
  Association,
  useGetAssociationsQuery,
} from 'manage-tritag/services/api/endpoints/associations'
import { DateTimePicker } from '@mui/x-date-pickers'
import Spinner from '../loading/spinner'

interface UniformReportModalProps {
  setModal: (value: boolean) => void
  reportData?: any
  reportLoading?: boolean
  reportReset?: any
  generate: (params: GetUniformReportParams) => void
}

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = styled('div')`
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  max-width: 550px;
`

const StyledButton = styled(Button)`
  font-size: 0.8rem;
`

const UniformReportModal: FC<UniformReportModalProps> = ({
  setModal,
  reportData,
  reportLoading,
  reportReset,
  generate,
}) => {
  const { data: seasons } = useGetSeasonsQuery({
    offset: 0,
    limit: 100000,
    search: '',
  })

  const { data: associations } = useGetAssociationsQuery({
    offset: 0,
    limit: 100000,
    search: '',
  })

  const [association, setAssociation] = useState<string>('')
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const [season, setSeason] = useState<string>('')

  const handleAssociationChange = (e: any) => {
    const newAssociation = e.target.value
    setAssociation(newAssociation)
  }

  const handleSeasonChange = (e: any) => {
    const newSeason = e.target.value
    setSeason(newSeason)
  }

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setStartDate(date)
    }
  }

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setEndDate(date)
    }
  }

  const onClose = () => {
    setAssociation('')
    setSeason('')
    setEndDate(null)
    setStartDate(null)
    reportReset()
    setModal(false)
  }

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p id="transition-modal-title" className="montserrat bold">
            Uniform Report
          </p>
          {reportData && (
            <p id="transition-modal-description" className="montserrat">
              Your report is now ready to download.
            </p>
          )}
          {!reportData && !reportLoading && (
            <>
              <p id="transition-modal-description" className="montserrat">
                Fill out the optional fields below and when you're ready click
                generate to build the report.
              </p>
              <div style={{ paddingBottom: '15px' }}>
                <FormControl
                  variant="filled"
                  // className={classes.formControl}
                >
                  <InputLabel shrink htmlFor="filled-homeTeam-native-simple">
                    Association
                  </InputLabel>
                  <Select
                    native
                    sx={{ minWidth: '300px', marginRight: '1rem' }}
                    value={association}
                    onChange={handleAssociationChange}
                    inputProps={{
                      name: 'association',
                      id: 'filled-association-native-simple',
                      tabIndex: 1,
                    }}
                  >
                    <option key="" value="">
                      None
                    </option>
                    {(associations || []).map((a: Association) => (
                      <option key={a._id} value={a._id}>
                        {a.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ paddingBottom: '15px' }}>
                <FormControl
                  variant="filled"
                  // className={classes.formControl}
                >
                  <InputLabel shrink htmlFor="filled-homeTeam-native-simple">
                    Season
                  </InputLabel>
                  <Select
                    native
                    sx={{ minWidth: '300px', marginRight: '1rem' }}
                    value={season}
                    onChange={handleSeasonChange}
                    inputProps={{
                      name: 'season',
                      id: 'filled-season-native-simple',
                      tabIndex: 2,
                    }}
                  >
                    <option key="" value="">
                      None
                    </option>
                    {(seasons || []).map((s: Season) => (
                      <option key={s._id} value={s._id}>
                        {s.name} {`${s.currentSeason ? ' (current)' : ''}`}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ paddingBottom: '15px' }}>
                <FormControl variant="filled">
                  <DateTimePicker
                    label="Start Date / Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    InputProps={{ error: false }}
                    renderInput={(renderParams: any) => (
                      <TextField
                        id="filled-basic"
                        label="Start Date / Time"
                        variant="filled"
                        inputProps={{
                          name: 'time',
                          id: 'filled-time-native-simple',
                          tabIndex: 3,
                        }}
                        sx={{ minWidth: '300px', marginRight: '1rem' }}
                        {...renderParams}
                      />
                    )}
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl variant="filled">
                  <DateTimePicker
                    label="End Date / Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    InputProps={{ error: false }}
                    renderInput={(renderParams: any) => (
                      <TextField
                        id="filled-basic"
                        label="End Date / Time"
                        variant="filled"
                        inputProps={{
                          name: 'time',
                          id: 'filled-time-native-simple',
                          tabIndex: 4,
                        }}
                        sx={{ minWidth: '300px', marginRight: '1rem' }}
                        {...renderParams}
                      />
                    )}
                    onChange={handleEndDateChange}
                    value={endDate}
                  />
                </FormControl>
              </div>
            </>
          )}
          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={onClose}
              style={{
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
              }}
            >
              Cancel
            </StyledButton>
            {reportLoading && <Spinner size={22} />}
            {reportData && (
              <CSVLink
                filename="Uniform Report"
                style={{ textDecoration: 'none', color: 'black' }}
                data={reportData.data}
                onClick={onClose}
              >
                <StyledButton
                  variant="contained"
                  color="primary"
                  className="montserrat"
                  style={{
                    padding: '0.4rem 1.5rem 0.4rem 1.5rem',
                    background: 'red',
                  }}
                >
                  Download
                </StyledButton>
              </CSVLink>
            )}
            {!reportData && !reportLoading && (
              <StyledButton
                variant="contained"
                color="primary"
                className="montserrat"
                onClick={() =>
                  generate({
                    association: association ?? undefined,
                    endDate: endDate ? endDate.toString() : undefined,
                    startDate: startDate ? startDate.toString() : undefined,
                    season: season ?? undefined,
                  })
                }
                style={{
                  padding: '0.4rem 1.5rem 0.4rem 1.5rem',
                  background: 'red',
                }}
              >
                Generate
              </StyledButton>
            )}
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

UniformReportModal.defaultProps = {
  reportData: undefined,
  reportLoading: false,
  reportReset: () => {},
}

export default UniformReportModal
